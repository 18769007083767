@use "../globals/vars" as *;
@use "../globals/mixins" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .container,
.container {
  // color for all child elements
  

  // width: 100%;
  // max-width: calc( var(--container-max-width) + var(--container-default-padding) * 2 );
  margin: 0 auto;
  padding: 0;
  // border-radius: 40px;
  // min-height: 572px;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 10px;

  /* ------ стили для Десктопа ------ */
  // @include vp--1023 {
  //   padding: {
  //     right: var(--container-default-padding);
  //     left: var(--container-default-padding);
  //   };
  // }
}
