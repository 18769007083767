@use "../globals/vars" as *;
@use "../globals/mixins" as *;
@use "../globals/typography" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .ui-button-second,
.ui-button-second {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
  margin: 0;
  padding: 15px 49px 17px 49px;
  @include fs-btn-md;
  text-transform: lowercase;
  color: $text-color-dark;
  background-color: $color-default-white;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  $properties-for-transition: color, background-color, border-color, opacity;
  @include transition-settings($properties-for-transition);

  &::before {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: inherit;
    z-index: 1;
    transition-property: opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease;
  }

  &::before {
    border: 1px dashed $color-default-white;
    opacity: 0;
  }

  /* ------ Интерактивные стили ------ */
  &:hover,
  &:focus-visible {
    background-color: hsla(34, 6%, 23%, 1);
    color: $color-default-white;

    &::before{
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.45;
    cursor: default;
    pointer-events: none;
  }
}
