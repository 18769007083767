@use "../globals/vars" as *;
@use "../globals/mixins" as *;
@use "../globals/typography" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .flags-creation-form,
.flags-creation-form {
  --color-dark-hsl: 4, 6%, 23%;
  --color-accent-hsl: 41, 75%, 54%;

  position: relative;

  display: flex;
  gap: 0;
  flex-direction: column;
  justify-content: space-between;
  // flex-grow: 1;

  width: 100%;
  max-width: 1200px;
  // height: 100%;
  min-height: 572px;
  // min-height: max(100vh, 560px);
  border-radius: 16px;
  background-color: $color-default-white;

  // padding: {
  //   top: 32px;
  //   bottom: 32px;
  // };

  // overflow: hidden;
  color: $text-color-dark;
  // font-family: var(--font-main);
  font-family: "Manrope";

  @include vp--767 {
    border-radius: 40px;
  }

  &__actions-top {
    display: flex;
    justify-content: space-between;
    height: 0;
    // margin-bottom: 40px;

    // padding: {
    //   right: var(--container-default-padding);
    //   left: var(--container-default-padding);
    // };

    /* ------ стили для Десктопа ------ */
    @include vp--1023 {
      padding: 0;
    }
  }

  &__actions-bottom {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
  }


  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: hsla(34, 6%, 23%, 1);
    padding: 16px 24px;
    flex-shrink: 0;
    position: relative;
    border-radius: 0 0 16px 16px;

    // padding: {
    //   right: var(--container-default-padding);
    //   left: var(--container-default-padding);
    // };

    /* ------ стили для Десктопа ------ */
    @include vp--767 {
      padding: 16px 32px;
      border-radius: 0 0 40px 40px;
    }
  
    @include vp--1279 {
      padding: 16px 48px;
    }
  }


  &__amount-title {
    // @include default-font-settings;
    font-size: 12px;
    line-height: 19px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: $color-default-white;
  }

  &__amount-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }

  &__amount {
    // @include default-font-settings;

    // font-size: 18px;
    // color: $color-default-black;
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;
    letter-spacing: 0.75px;
    color: $color-accent;
    margin: 0;
    font-family: var(--font-accent);
  }

  &__amount-currency {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
  }

  &__birdy {
    display: none;
    position: absolute;
    right: 60px;
    top: 0;
    transform: translateY(calc(-100% + 15px));
    width: 123px;
    height: auto;

    @include vp--1279 {
      display: block;
    }
  }

  &--modal &__birdy {
    display: none;
  }
}
