@use "../globals/vars" as *;
@use "../globals/mixins" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .flags,
.flags {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  row-gap: 26px;

  width: 100%;

  /* ------ стили для Десктопа ------ */
  @include vp--1023 {
    flex-wrap: nowrap;
    // justify-content: space-between;
    justify-content: center;
  }

  @media(max-width: 767px) {
    &.flags--mobile {
      max-width: 350px;
      justify-content: flex-start;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    
    &--is-hidden-on-mobile {
      display: none;
    }

    /* ------ стили для Десктопа ------ */
    @include vp--767 {
      &--is-hidden-on-mobile {
        display: block;
      }
    }
  }


  &__button {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 0;
    height: 0;

    margin: 0;
    padding: 0;

    background-color: hsla(var(--color-dark-hsl), 0);

    border: {
      top: var(--flag-picture-height) solid transparent;
      // right: var(--flag-picture-vertical-border-size) solid $color-body-bg;
      right: var(--flag-picture-vertical-border-size) solid $color-default-white;
      bottom: none;
      // left: var(--flag-picture-vertical-border-size) solid $color-body-bg;
      left: var(--flag-picture-vertical-border-size) solid $color-default-white;
    };

    cursor: pointer;
    outline: none;

    $properties-for-transition: background-color;

    @include transition-settings($properties-for-transition);

    &--active {
      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
      }

      &::before {
        bottom: -20px;

        width: 14px;
        height: 14px;

        background: {
          color: transparent;
          image: url("../images/active-marker.svg");
          repeat: no-repeat;
          position: center center;
        };

        animation: {
          name: active_marker;
          duration: 1.5s;
          timing-function: ease;
          iteration-count: infinite;
        };
      }

      &::after {
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);

        background: {
          color: transparent;
          image: url("../images/active-flag.svg");
          repeat: no-repeat;
          position: center center;
          size: 100% 100%;
        };

        width: calc( var(--flag-picture-width) + 10px );
        height: calc( var(--flag-picture-height) + 10px );
      }
    }

    &--deactivated {
      cursor: default;
    }

    /* ------ Интерактивные стили ------ */
    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: hsla(var(--color-dark-hsl), 0.08);
    }
  }

  &__button--colored &__button-img {
    display: block;
  }

  /* ------ Настройки для отображения SVG-картинок ------ */
  &__button:hover > &__button-icon,
  &__button:focus > &__button-icon {
    fill: $color-active;
    stroke: $color-active;
  }

  &__button:disabled > &__button-icon {
    // fill: $color-border;
    // stroke: transparent;
    opacity: 0.4;
  }

  &__button--active > &__button-icon {
    opacity: 0;
  }

  &__button--colored > &__button-icon {
    display: none;
  }

  &__button-img {
    position: absolute;
    bottom: 100%;
    left: calc( -1 * var(--flag-picture-vertical-border-size) );
    z-index: -1;

    display: none;

    width: var(--flag-picture-width);
    height: var(--flag-picture-height);
  }

  &__button-icon {
    position: absolute;
    bottom: 100%;
    left: calc( -1 * var(--flag-picture-vertical-border-size) );

    // fill: $color-border;
    fill: hsla(var(--color-dark-hsl), 0.4);
    stroke: transparent;

    $properties-for-transition: fill, stroke, opacity;

    @include transition-settings($properties-for-transition);
  }
}


// ------ Настройки анимации для маркера Активного Флажка ------
@keyframes active_marker {
  0%,
  100% {
    bottom: -26px;
  }

  50% {
    bottom: -20px;
  }
}
