@use "../globals/vars" as *;
@use "../globals/mixins" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .flags-options,
.flags-options {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 10px;
  z-index: 1;
  position: relative;

  &--column {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    @include vp--767 {
      flex-direction: row;
    }
  }

  &-wrapp {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0 24px;

    @include vp--767 {
      width: calc(100% + 64px);
      margin-left: -32px;
      padding: 0 32px;
    }

    @include vp--1023 {
      width: calc(100% + 96px);
      margin-left: -48px;
      padding: 0 48px;
    }
  }

  .swiper & {
    flex-wrap: nowrap;
    gap: 0;
  }

  &--slider {
    // @include pseudo-slide-effect( var(--default-container-padding-value) );
    // max-width: var(--container-max-width);
  }

  &__item {
    position: relative;
  }


  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 2px;

    // background-color: transparent;
    // border: 1px solid $color-border;
    // border-radius: 50px;

    cursor: pointer;

    $properties-for-transition: color, background-color, border-color, opacity;

    @include transition-settings($properties-for-transition);


    /* ------ Интерактивные стили ------ */
    &:hover:not(label):before {
      opacity: 1;
    }

    &:hover:not(label):after {
      opacity: 0;
    }

    &:hover,
    &:focus {
      // color: $color-default-black;
      // border-color: $color-acccent;
    }

    &:active {
      color: $color-default-white;
      // background-color: $color-active;
      // border-color: $color-active;
    }
  }

  // --- Стили для Подписи при интерактивных состояниях Кнопки  ---
  &__button:hover > &__option-description,
  &__button:focus > &__option-description {
    // color: $color-default-black;
  }

  &__button:active > &__option-description {
    // color: $color-default-white;
  }


  &__image {
    display: block;

    width: 45px;
    height: 45px;

    // border-radius: 50%;
    border-radius: 10px;

    object-fit: contain;
    object-position: center;
  }


  &__option-description {
    @include default-font-settings;

    display: block;

    min-width: max-content;

    margin: 0 16px;
    padding: 0;

    $properties-for-transition: color;

    @include transition-settings($properties-for-transition);
  }



  // *** Настройки для :checked-состояния у скрытого Радиобаттона ***
  &__hidden-input:checked + &__label {
    // color: $color-default-white;
    // background-color: $color-active;
    // border-color: $color-active;
    color: $color-accent;
    border: 1px dashed hsla(34, 6%, 23%, 0);
  }

  &__hidden-input:checked + &__label::before {
    opacity: 1;
  }

  &__hidden-input:checked + &__button & {
    &__option-description {
      color: $color-accent;
    }
  }
}
