@use "../globals/vars" as *;
@use "../globals/mixins" as *;
@use "../globals/typography" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .ui-link,
.ui-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: max-content;
  margin: 0;
  padding: 8px 0;
  @include fs-btn-md;
  background-color: hsla(0, 0%, 100%, 0);
  border: none;
  cursor: pointer;
  color: $color-default-white;
  // $properties-for-transition: color, background-color, border-color, opacity;
  // @include transition-settings($properties-for-transition);
  position: relative;

  // &--no-border {
  //   border: none;
  // }

  // &::after,
  // &::before {
  //   content: '';
  //   width: calc(100% + 2px);
  //   height: calc(100% + 2px);
  //   position: absolute;
  //   top: -1px;
  //   left: -1px;
  //   border-radius: inherit;
  //   z-index: 1;
  //   transition-property: opacity;
  //   transition-duration: 0.4s;
  //   transition-timing-function: ease;
  // }

  // &::before {
  //   border: 1px solid $color-accent;
  //   opacity: 0;
  // }

  // &::after {
  //   background-color: hsla(34, 6%, 23%, 1);
  //   opacity: 0;
  // }

  /* ------ Интерактивные стили ------ */
  // &:hover {
  //   border: 1px dashed hsla(34, 6%, 23%, 0);
  // }

  // &:hover::after {
  //   opacity: 0.08;
  // }

  // &:active,
  // &--active {
  //   color: $color-accent;
  // }

  // &:active::before,
  // &--active::before {
  //   opacity: 1;
  // }

  // &:active::after,
  // &--active::after {
  //   opacity: 0;
  // }

  // &:disabled,
  // &--disabled {
  //   color: $color-border;
  //   background-color: transparent;
  //   opacity: 0.5;

  //   cursor: default;
  //   pointer-events: none;
  // }


  /*
   * ========================================
   * ------ Стили для связанных Иконок ------
   * ========================================
   */
  &__icon {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    // stroke: currentColor;
    fill: currentColor;
    background-color: hsla(0, 0%, 100%, 0.2);
    transition-property: background-color;
    transition-duration: 0.4s;
    transition-timing-function: ease;

    // &--left-side {
    //   margin-right: 8px;
    // }

    // & svg {
    //   fill: currentColor;
    // }
    &--right-side {
      transform: rotate(180deg);
      // margin-left: 8px;
    }
  }

  &:hover &__icon {
    background-color: $color-accent;
  }
}
