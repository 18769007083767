@use "vars" as *;


/*
  Миксин для удаления маркеров элементов маркированных списков
  и обнуления отступов
*/
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}


/*
  Миксин для добавления элементу дефолтных настроек Шрифта
*/
@mixin default-font-settings {
  font: {
    size: 12px;
    weight: 500;
  };
  line-height: 1;
  color: $color-text-default;
  text-decoration: none;
}


/*
  Миксин для настроек плавности интерактивных элементов
*/
@mixin transition-settings ($property, $duration: 0.4s, $timing-func: ease) {
  transition: {
    property: $property;
    duration: $duration;
    timing-function: $timing-func;
  }
}


/*
  Миксин для создания визуального эффекта Псевдослайдера
*/
@mixin pseudo-slide-effect ($indent-value) {
  $scrollbar-size: 4px;

  display: flex;
  flex-wrap: nowrap;
  width: 100vw;
  margin: {
    right: calc( -1 * $indent-value );
    left: calc( -1 * $indent-value );
  };
  padding: {
    right: $indent-value;
    left: $indent-value;
    bottom: $scrollbar-size;
  };
  overflow-x: auto;

  scrollbar-width: thin;
  scrollbar-color: $color-text-default $color-border;

  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-border;
    border-radius: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-text-default;
    border-radius: $scrollbar-size;
  }

  /* ------ стили для Десктопа ------ */
  @media (min-width: calc($vp--1024 - 1px)) {
    width: 100%;
  }
}


/*
  Миксины для Медиавыражений
*/
@mixin vp--1279 {
  @media (min-width: calc($vp--1280 - 1px)) {
    @content;
  }
}
@mixin vp--1023 {
  @media (min-width: calc($vp--1024 - 1px)) {
    @content;
  }
}
@mixin vp--767 {
  @media (min-width: calc($vp--768 - 1px)) {
    @content;
  }
}
