@use "vars" as *;


// === ГЛОБАЛЬНЫЕ СТИЛИ ===
:root {
  --default-container-padding-value: 16px;
  --container-max-width: 604px;

  --flag-picture-width: 64px;
  --flag-picture-height: 68px;
  --flag-picture-vertical-border-size: calc( var(--flag-picture-width) / 2 );
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  // display: flex;
  // flex-direction: column;
  // width: 100vw;
  // min-width: $width--min;
  // min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: $color-default-black;
  background-color: #f4ebdf;

  // *** Для устранения горизонтальных скроллов в IE 11, Edge и Safari ***
  overflow-x: hidden;
}
