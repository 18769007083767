@use "../globals/vars" as *;
@use "../globals/mixins" as *;

/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .flags-box,
.flags-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  // min-height: 200px;

  /* ------ стили для Десктопа ------ */
  @include vp--1023 {
    // margin: 0;
    padding: 0;
  }


  &__ribbon {
    position: relative;
    width: 100%;
    height: 10px;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    font-size: 0;
    background: {
      color: transparent;
      repeat: repeat;
      position: center center;
      size: auto auto;
    };
    border: 1px solid $color-active;
    cursor: pointer;
    $properties-for-transition: background-color;
    @include transition-settings($properties-for-transition);
  }

  &__ribbon-icon {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
    width: 6px;
    height: 12px;
    stroke: $color-active;
  }
}
