/*
==============================================================================================================
--------------------------------------------- Шрифт «Montserrat» ---------------------------------------------
==============================================================================================================
*/

// *** жирность «Regular» ***
@font-face {
  font-family: "Forum";
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src:
    url("../fonts/Forum-Regular.woff2") format("woff2"),
    url("../fonts/Forum-Regular.woff") format("woff");
}


// *** жирность «Medium» ***
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;

  src:
    url("../fonts/Manrope-Medium.woff2") format("woff2"),
    url("../fonts/Manrope-Medium.woff") format("woff");
}
