@use "../globals/vars" as *;
@use "../globals/mixins" as *;
@use "../globals/typography" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .ui-button,
.ui-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
  margin: 0;
  padding: 13px 24px;
  // @include default-font-settings;
  @include fs-btn-sm;
  background-color: hsla(0, 0%, 100%, 0);
  border: 1px dashed hsla(var(--color-dark-hsl), 0.4);
  border-radius: 12px;
  cursor: pointer;
  $properties-for-transition: color, background-color, border-color, opacity;
  @include transition-settings($properties-for-transition);
  position: relative;

  &--no-border {
    border: none;
  }

  &::after,
  &::before {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: inherit;
    z-index: 1;
    transition-property: opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    pointer-events: none;
  }

  &::before {
    border: 1px solid $color-accent;
    opacity: 0;
  }

  &::after {
    // border: 1px dashed hsla(34, 6%, 23%, 1);
    background-color: hsla(var(--color-dark-hsl), 1);
    opacity: 0;
  }

  /* ------ Интерактивные стили ------ */
  &:hover {
    border: 1px dashed hsla(var(--color-dark-hsl), 0);
  }

  &:hover::after {
    opacity: 0.08;
  }

  :checked + &:hover::after {
    opacity: 0;
  }

  &:active,
  &--active {
    color: $color-accent;
  }

  &:active::before,
  &--active::before {
    opacity: 1;
  }

  &:active::after,
  &--active::after {
    opacity: 0;
  }

  &:disabled,
  &--disabled {
    color: $color-border;
    background-color: transparent;
    opacity: 0.5;

    cursor: default;
    pointer-events: none;
  }


  /*
   * ========================================
   * ------ Стили для связанных Иконок ------
   * ========================================
   */
  &__icon {
    display: block;
    stroke: currentColor;

    &--left-side {
      margin-right: 8px;
    }

    &--right-side {
      transform: rotate(180deg);
      margin-left: 8px;
    }
  }
}
