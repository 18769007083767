@use "../globals/vars" as *;
@use "../globals/mixins" as *;
@use "../globals/typography" as *;


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .flags-creation-step,
.flags-creation-step {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-inline-size: 0;
  margin: 0;
  padding: {
    top: 24px;
    right: 24px;
    bottom: 64px;
    left: 24px;
  };
  border: none;
  opacity: 0;
  pointer-events: none;
  $properties-for-transition: left, transform, opacity;
  @include transition-settings($properties-for-transition);
  transition-duration: 0.4s, 0.4s, 0.2s;
  transition-delay: 0s, 0s, 0s;
  grid-column: 1 / 1;
  grid-row: 1 / 1;

  @include vp--767 {
    padding: 32px;
  }

  @include vp--1279 {
    padding: 48px;
  }

  &-wrapp {
    flex-grow: 1;
    display: grid;
    grid: {
      template-columns: 1fr;
      template-rows: 1fr;
    };
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @media(max-width: 1279px) {
    .flags-creation-form--modal & {
      padding-top: 96px;
    }
  }

  &--is-active {
    opacity: 1;
    pointer-events: all;
    transition-duration: 0.4s, 0.4s, 0.4s;
    transition-delay: 0s, 0s, 0.2s;
  }

  &--is-inactive-left {
    transform: translateX(-100%);
  }

  &--is-inactive-right {
    transform: translateX(100%);
  }

  &__text {
    margin: 0;
    padding: 0;
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 0;
    margin-bottom: 30px;
    padding: 0;
  }

  &__counter {
    @include fs-caption;
    color: $color-accent;
  }

  &__title {
    @include fs-title;
    margin-top: 0.8rem;
    text-transform: uppercase;
  }

  &__description {
    @include fs-text-md;
  }
}
