@use "../globals/vars" as *;
@use "../globals/mixins" as *;


// === Класс для доступного сокрытия элементов ===
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}


// === Класс для блокировки скролла при открытии Модального окна ===
.body-scroll-locked {
  overflow: hidden;
}


.is-hidden {
  display: none !important;
}
