@use "../globals/vars" as *;
@use "../globals/mixins" as *;
@use "sass:math";


/*
  Селектор :is(#allrecords) необходим
  для переопределения дефолтных стилей «Тильды»
*/
:where(#allrecords) .form-actions,
.form-actions {
  display: flex;
  align-items: center;
  // min-height: 36px;

  &__action {
    &--is-hidden-on-mobile {
      display: none;

      /* ------ стили для Десктопа ------ */
      @include vp--767 {
        display: flex;
      }
    }

    &--is-hidden-on-desktop {
      display: flex;

      /* ------ стили для Десктопа ------ */
      @include vp--1023 {
        display: none;
      }
    }
  }

  @media(max-width: 787px) {
    &__action-back {
      position: absolute;
      top: -12px;
      right: 24px;
      transform: translateY(-100%);
      z-index: 2;
    }

    &__action-back.ui-link {
      color: $color-accent;
    }

    &__action-back .ui-link__icon {
      background-color: hsla(var(--color-accent-hsl), 0.2);
    }

    &__action-back:hover .ui-link__icon,
    &__action-back:active .ui-link__icon {
      color: $color-default-white;
    }
  }

  &__close-action {
    // $close-action--box-side-size: 16px;
    // $close-action--box-size-with-gap: calc($close-action--box-side-size + 8px);
    // $close-action--cross-line-width: math.hypot($close-action--box-side-size, $close-action--box-side-size);
    display: none;
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 24px;

    width: 48px;
    height: 48px;

    margin: 0;
    padding: 0;

    font-size: 0;

    background-color: hsla(var(--color-dark-hsl), 0.05);
    color: hsla(var(--color-dark-hsl), 1);
    border: none;
    border-radius: 50%;

    cursor: pointer;

    $properties-for-transition: background-color, color;

    @include transition-settings($properties-for-transition);

    & svg {
      fill: currentColor;
    }

    // &::before,
    // &::after {
    //   content: '';

    //   position: absolute;
    //   top: 50%;
    //   left: 50%;

    //   display: block;

    //   width: $close-action--cross-line-width;
    //   height: 1px;

    //   background-color: $text-color-dark;

    //   $properties-for-transition: background-color;

    //   @include transition-settings($properties-for-transition);
    // }

    // &::before {
    //   transform: translate(-50%, -50%) rotate(45deg);
    // }

    // &::after {
    //   transform: translate(-50%, -50%) rotate(-45deg);
    // }


    /* ------ Интерактивные стили ------ */
    &:hover,
    &:focus {
      background-color: hsla(var(--color-dark-hsl), 0);
      color: hsla(var(--color-accent-hsl), 1);

      // &::before,
      // &::after {
      //   background-color: $color-default-white;
      // }
    }

    // &:active {
    //   opacity: 0.7;
    // }
  }

  .flags-creation-form--modal &__close-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
