:root {
  // /* fonts */
  --font-main: 'Manrope';
  --font-accent: 'Forum';


  /* font caption */
  --fs-caption: 12px;
  --lh-caption: 12px;
  --ls-caption: -0.25px;


  /* font title */
  --fs-title: 22px;
  --lh-title: 24px;
  --ls-title: 0.75px;


  /* font primary */
  --fs-text-md: 14px;
  --lh-text-md: 21px;
  --ls-text-md: -0.25px;


  /* font buton */
  --fs-btn-md: 16px;
  --lh-btn-md: 16px;
  --ls-btn-md: -0.25px;


  /* font buton_small */
  --fs-btn-sm: 14px;
  --lh-btn-sm: 14px;
  --ls-btn-sm: -0.25px;
}

@media(min-width: 768px) {
  :root {
    /* font title */
    --fs-title: 28px;
    --lh-title: 31px;


    /* font primary */
    --fs-text-md: 16px;
    --lh-text-md: 24px;
  }
}

@media(min-width: 1280) {
  :root {
    /* font title */
    --fs-title: 30px;
    --lh-title: 33px;
  }
}

@mixin fs-caption {
  font-size: var(--fs-caption);
  line-height: var(--lh-caption);
  letter-spacing: var(--ls-caption);
  font-weight: 500;
  font-family: var(--font-main);
}

@mixin fs-title {
  font-size: var(--fs-title);
  line-height: var(--lh-title);
  letter-spacing: var(--ls-title);
  font-weight: 400;
  font-family: var(--font-accent);
}

@mixin fs-text-md {
  font-size: var(--fs-text-md);
  line-height: var(--lh-text-md );
  letter-spacing: var(--ls-text-md );
  font-weight: 500;
  font-family: var(--font-main);
}

@mixin fs-btn-md {
  font-size: var(--fs-btn-md);
  line-height: var(--lh-btn-md);
  letter-spacing: var(--ls-btn-md);
  font-weight: 500;
  font-family: var(--font-main);
}

@mixin fs-btn-sm {
  font-size: var(--fs-btn-sm);
  line-height: var(--lh-btn-sm);
  letter-spacing: var(--ls-btn-sm);
  font-weight: 500;
  font-family: var(--font-main);
}
